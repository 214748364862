import React, { useState } from "react";
import Layout from "../../components/Layouts";
// import VisTreeView from "./VisTreeView";
import Loadable from 'react-loadable';
import katex from 'katex'
import {
  Slider,
  Row,
  Col,
  Popover,
  Collapse,
  Icon,
  Divider,
  Tag
} from "antd";


import "./index.css";

export const frontmatter = {
  title: `Word Tree: Transformers Edition`,
  written: "2021-06-26",
  updated: "2021-07-12",
  layoutType: `post`,
  contentType: "dataviz",
  path: "/wordtree-v2/",
  category: "VISUALISATION",
  image: `./poster.png`,
  description: "Generate Trajectories from the Transformers based Language Model",
};

const customPanelStyle = {
  // background: '#f7f7f7',
  fontFamily: "Charter,Georgia,serif",
  background: 'white',
  borderRadius: 4,
  marginBottom: 24,
  border: 0,
  letterSpacing: 2,
  overflow: 'hidden',
  lineHeight: 1.45,
  fontSize: "1.4em",
  // fontSize: "16px",
  // fontWeight: "600"
};

const { Panel } = Collapse;

const LoadableVisTreeView = Loadable({
  loader: () => import('./VisTreeView'),
  loading() {
    return <div>Loading...</div>
  },
})



export default function WordTreeV2(props) {
  return (
    <Layout data={props.data} location={props.location}>
      {/* <FloatingPanel visible={showFloater} /> */}
      <div style={{ maxWidth: "800px", margin: "0px auto" }}>
        <h1 className="header-title">
          Word Tree: Transformers Edition
        </h1>
        <h4
          className="header-subtitle"
          style={{ marginTop: 20, marginBottom: 10 }}
        >          
          Desktop Version | 13 Jul, 2021
        </h4>
        <div className="story-content" style={{ marginBottom: 20 }}>
        <div className="col-sm-6">
            <div className="video-container">
              <iframe 
                width="560" height="315" src="https://www.youtube.com/embed/lE-B99H6oTY" 
                title="End of World" frameborder="0" 
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>            
            </div>
            <p style={{ fontSize: 12, textAlign: "center" }}>
              Trajectories are intriguing
            </p>
          </div>
          <div>            
            <h2 className="header-2">Background</h2>
            <p className="para para1">
                The first version of <a className="link" href="/wordtree">Word Tree</a> visualized the model trained as part of <a className="link" href="/generativetext">Neural Text Generation</a> post. It 
                showed the words sampled from a set of small RNN based models trained on various corpora such as 
                Asimov, Jokes, etc. The models were trained along the lines of Karpathy's post 
                on <a className="link" href="http://karpathy.github.io/2015/05/21/rnn-effectiveness/">Unreasonable Effectiveness of Recurrent Neural Networks</a> <Tag color="#9370DB">must-read</Tag>
            </p>
            <p className="para para1">
              The demo model used in the neural text generation post was very limiting in terms of their generative capabilities, i.e., consistently producing meaningful 
              suggestions. Nonetheless, it was fascinating to see it uncover the structure of the language. The motivation behind the demo app was to 
              try and create a specialized version of the model that could provide task or domain-specific suggestions. For example, a model to assist in writing jokes or, to auto complete science fiction stories in the style of great Isaac Asimov.
            </p>
            <p className="para para1">
              <figure className="quote">
              <blockquote>                
                The model architecture will be considered infant compared to the transformers era in 
                terms of the number of parameters, model capacity, and training costs 😉.              
              </blockquote>
              </figure>
            </p>
            <p 
            className="para para1">
                I am somewhat surprised to see the demo still working well. I don’t remember touching it at all in the last three years or so.
                <details style={{marginTop: 10}} >
                  <summary style={{padding: 10}} >2017: Neural Text Generation Demo Web App <small>(Click Here)</small></summary>
                  <div style={{marginLeft: 20}}>
                    <Row>
                      <Col>
                        <a className="link" href="https://text.sprinkleai.com/textgen" target="_blank" rel="noopener noreferrer"><img src="https://s3.amazonaws.com/katnoria.com/static/texgen_snap.png" /></a>
                      </Col>
                    </Row>
                    <Row>
                      <Col style={{fontWeight: "bold", fontSize: 14}} >
                        <a className="link" href="https://text.sprinkleai.com/textgen" style={{marginTop:10}} target="_blank" rel="noopener noreferrer">
                        TRY DEMO APP
                        </a>
                      </Col>
                    </Row>
                  </div>
              </details>
            </p>
          </div>
          <div>
          <h2 className="header-2">About The Video</h2>
            <p className="para para1">
              The tree in the video visualizes a few sampled trajectories generated using a <a className="link" href="https://en.wikipedia.org/wiki/Language_model">language model</a>.
              We use <a className="link" href="https://arxiv.org/abs/1904.09751">nucleus sampling</a> to sample the model predictions and generate the sentence.
              The previous version of <a className="link" href="/wordtree">word tree</a> used beam search.
              In general, nucleus sampling tends to generate diverse as well as better quality text. In my opinion, the idea is somewhat similar to the <a className="link" href="/hillclimb">Adaptive noise scaling</a> approach 
              where we dynamically expand or contract the candidate pool or search radius.
            </p>
            <p className="para para1">
              <figure className="quote">
                <blockquote>
                  By sampling text from the dynamic nucleus of the probability distribution, which allows for diversity while effectively truncating the less reliable tail of the distribution, the resulting text better demonstrates the quality of human text, yielding enhanced diversity without sacrificing fluency and coherence.
                </blockquote>
              </figure>
              <p style={{ fontSize: 12, textAlign: "center" }}>
                Source: The Curious Case of Neural Text Degeneration (i.e. The Nucleus Sampling Paper)
              </p>
            </p>
            <h6 style={{ fontWeight: "bold" }}>See Also</h6>
            <div>
              <ul style={{ marginLeft: 20, marginBottom: 10}} className="para2">
                <li style={{ paddingTop: 10, paddingBottom: 5 }}><a className="link" href="/nlg-beamsearch">Beam Search: Effect of Parameters</a></li>
                <li style={{ paddingBottom: 5 }}><a className="link" href="/nlg-decoders">Visual Guide to Decoding Algorithms</a></li>
              </ul>
              <h4 className="header-3">
                  How does it work?
              </h4>
              <div class="para para1">
                <p>
                  At the very high level, this is how it works:
                </p>
                <div style={{ margin: 20}}>
                  <ol>
                    <li>Split sentences into individual sentence</li>
                    <li>Split each sentence into words</li>
                    <li>For every word in a sentence, concatenate with previous words and pass through the language model</li>
                    <li>Decode the model predictions to generate a complete sentence </li>
                  </ol>
                </div>
              </div>
              <div className="para para1">
                <div>
                  <p className="para">Visually, the flow looks like this:</p>
                  <img src="https://s3.amazonaws.com/katnoria.com/static/wordtree/wt-flow-2.png" />
                  <p style={{ fontSize: 12, textAlign: "center" }}>
                TODO: Add animated version with an example
              </p>
                </div> 
                <div style={{ marginTop: 20}}>
                  <p className="para1" style={{ marginBottom: 20 }}>A few points <strong>worth mentioning</strong>:</p>
                  <p className="para1" style={{ marginBottom: 10 }}>
                  ⇢ Why aren't we passing the first two words to the model? Well, technically, we could but having more context helps the model provide better predictions.
                    For example, in the diagram above, we do not do anything for the first two words.
                  </p>
                  <p className="para1" style={{ marginBottom: 10 }}>
                  ⇢ We can swap out the language model with any domain-centric or next state-of-the-art model
                  </p>
                  <p className="para1" style={{ marginBottom: 10 }}>
                  ⇢ We can swap the <a className="link" href="/nlg-decoders">decoding algorithm</a> as well
                  </p>
                  <p className="para1" style={{ marginBottom: 10 }}>
                  ⇢ What do you mean by trajectory? The trajectory here is the path taken by the sentence. Each trajectory is created by repeatedly sampling the next word until the stopping criteria is met. You can read about stopping criteria <a className="link" href="/nlg-decoders">here</a>
                  </p>
                </div>
              </div>
              <p className="para">
                The rest of the post breaks down the demo in the video into individual plots.
              </p>

            </div>
            <h2 className="header-3" style={{marginBottom: 20, marginTop: 20}} >
              Plots
            </h2>
            <iframe src="https://open.spotify.com/embed/track/5DTOOkooKFUvWj1XQTFa09" width="100%" height="380" frameBorder="0" allowtransparency="true" allow="encrypted-media"></iframe>
            <div>
              <Collapse
                style={{ backgroundColor: 'white' }}
                bordered={true}
                expandIcon={({ isActive }) => <Icon type="caret-right" rotate={isActive ? 90 : 0} />}
                defaultActiveKey={['1']}
              >
                <Panel header="Why does the sun go on shining?" key="1" style={customPanelStyle}>
                  <div>                    
                    <LoadableVisTreeView                       
                      url="https://gist.githubusercontent.com/katnoria/51a2d999cbeff0d729ab5ab9957f76d8/raw/0f6f065cf105de0fa5fc6c241870ee6b85b113f1/end-of-word.json"
                      lineIndex={0}
                    />
                  </div>
                </Panel>
                <Panel header="Why does the sea rush to shore?" key="2" style={customPanelStyle}>
                <LoadableVisTreeView 
                      url="https://gist.githubusercontent.com/katnoria/51a2d999cbeff0d729ab5ab9957f76d8/raw/0f6f065cf105de0fa5fc6c241870ee6b85b113f1/end-of-word.json"
                      lineIndex={1}
                    />
                </Panel>                
                <Panel header="Don't they know it's the end of the world?" key="3" style={customPanelStyle}>
                <LoadableVisTreeView 
                      url="https://gist.githubusercontent.com/katnoria/51a2d999cbeff0d729ab5ab9957f76d8/raw/0f6f065cf105de0fa5fc6c241870ee6b85b113f1/end-of-word.json"
                      lineIndex={2}
                    />
                </Panel>
                <Panel header="'Cause you don't love me any more" key="4" style={customPanelStyle}>
                <LoadableVisTreeView 
                      url="https://gist.githubusercontent.com/katnoria/51a2d999cbeff0d729ab5ab9957f76d8/raw/0f6f065cf105de0fa5fc6c241870ee6b85b113f1/end-of-word.json"
                      lineIndex={3}
                    />
                </Panel>
              </Collapse>
            </div>            
            <div style={{ marginTop: 20, marginBottom: 20}} className="para1">
              Interestingly, the trajectories in last plot mostly converge to the same word.
              But well, 
              <span
                style={{marginLeft: 10, backgroundColor: "#DED4F4"}}
                dangerouslySetInnerHTML={{
                  __html: katex.renderToString("P(more \\mid {you \\ don't \\ love \\ me \\ any} )", {throwOnError: false})
                }}>
              </span> is most likely going be higher than other words in the vocabulary.
              We also see one of the most common with decoding algorithms where they are stuck <a className="link" href="https://arxiv.org/pdf/2012.14660.pdf">repeating themselves</a>.
              And, some of the sentences will not make sense.
            </div>       
            <div style={{ marginTop: 20, marginBottom: 20}} className="para1">
              The following video is another take on the same song but this time we use <b>image generation model</b> (CLIP + GAN) to generate the background images.
              Each image was generated based on the sentence you see on screen.
            </div>
            <div className="col-sm-6">
            <div className="video-container">
              <iframe 
                width="560" height="315" src="https://www.youtube.com/embed/p20dNyjqZw4" 
                title="End of World" frameborder="0" 
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>            
            </div>
            <p style={{ fontSize: 12, textAlign: "center" }}>
                Updated Video: Word Tree + Generative Image Model
            </p>
          </div>
            <div style={{ marginTop: 20, marginBottom: 20}} className="para1">
              <p>
                Both the the videos were created by <u>manually aligning</u> the song to the tree  
                animation. You know what will be cool? Automatically aligning the audio and animation 
                using <a href="https://alphacephei.com/vosk/" className="link">word boundary detection</a>.
              </p>
              <p>
                The following example shows automated speech transcription using VOX along side the audio waveform plot using Librosa. The recognized text is not quite there but it is a start 😀.
              </p>
              <img style={{ marginTop: 20, marginBottom: 20}} src="https://s3.amazonaws.com/katnoria.com/static/wordtree/auto-eow.png" />
              <p style={{ marginTop: 20, marginBottom: 20}} className="para1">
                Or do something even cooler ⬇
              </p>
              <img style={{ marginTop: 20, marginBottom: 20}} src="https://s3.amazonaws.com/katnoria.com/static/wordtree/automated-wt.png" />
            </div>
            <div style={{ marginTop: 20, marginBottom: 20}} className="para1">
              <p>But I am going to stop here 😀. Hoping to see it in action some day.</p>
              <LoadableVisTreeView 
                      url="https://gist.githubusercontent.com/katnoria/d7b516803d82c88f0b086098ac1e1251/raw/8b2fd7728222fa09b55342b461ff8df02e5c68af/action_someday-20210803090551.json"
                      lineIndex={0}   
                      height={300}
                      width={770}
                      backgroundColor="#f2f2f2"
                      edgeColor="black"
              />
            </div>
            <div>
              <p className="para para1">
                Finally, I am going to leave you with the following thoughts. As 
                I wrote them, I started looking for ways to visualize them. The 
                animation and the post is my attempt to imagine these thoughts.
              </p>              
              <p className="para para1">
              <figure className="quote">
                <blockquote>                              
                <p className="para para1">Trajectories are intriguing</p>
                <p className="para para1">
                  Of many choices available, only one that ever gets chosen. 
                  The rest become could've,  should've, or would've. 
                  Every step, every moment, every decision presents many possible paths - infinitely branching. 
                  Some more likely than the others. But one must choose. 
                </p>
                <p>
                  <b>Trajectories are fascinating</b>
                </p>
                              
                </blockquote>
                </figure>
                <p style={{ fontSize: 12, textAlign: "center" }}>
                  <i>No language models were used to write this one</i>
                </p>
              </p>
            </div>
            <h6 style={{ fontWeight: "normal" }}>If you wish to explore more about sampling trajectories, you may find the following useful:</h6>
            <div>
              <ul style={{ marginLeft: 20, paddingTop: 10, marginBottom: 10}} className="para2">
                <li style={{ paddingBottom: 5 }}><a className="link" href="/nb_montecarlo">Monte Carlo Methods</a></li>
                <li style={{ paddingBottom: 5 }}><a className="link" href="/nb_dqn_lunar">Deep Q Network</a></li>
                <li style={{ paddingBottom: 5 }}><a className="link" href="/ddpg-reacher">Deep Deterministic Policy Gradients</a></li>
              </ul>
            </div>
          </div>       
          <Divider />   
          <div>
            <h2 className="header-3" style={{marginBottom: 20, marginTop: 20}} >
              Up Next
            </h2>
            <div>
                <p>Back to the demo app that has been in works for a few months now 😅 </p>
                <img style={{ marginTop: 20, marginBottom: 20}} src="https://s3.amazonaws.com/katnoria.com/static/wordtree/img-explorer.gif" />
                <p>👨🏽‍💻....</p>
            </div>
          </div>
        </div>
      </div>
      <Divider />
      <div style={{marginTop: 20, marginBottom: 30}}>
        <p></p>
      </div>
    </Layout>
  );
}